<template>
  <div class="enterprise-info-container">
    <!-- 返回按钮 -->
    <button @click="$emit('go-back')" class="back-button">返回</button>

    <!-- 当前企业信息 -->
    <div class="current-enterprise-info">
      <span>{{ mode === 'add' ? '新增企业' : '企业信息' }}</span>
    </div>

    <!-- 新增模式 -->
    <div v-if="mode === 'add'">
      <div class="enterprise-box">
        <label>企业名称</label>
        <input v-model="enterprise.name" placeholder="请输入企业名称" />
      </div>
      <div class="enterprise-box">
        <label>区域</label>
        <select v-model="enterprise.regionId">
          <option v-for="region in regions" :key="region.id" :value="region.id">{{ region.name }}</option>
        </select>
      </div>
      <div class="enterprise-box">
        <label>地址</label>
        <input v-model="enterprise.address" placeholder="请输入地址" />
      </div>
      <div class="enterprise-box">
        <label>类型</label>
        <select v-model="enterprise.type">
          <option v-for="entry in ENTERPRISE_TYPE" :key="entry.name" :value="entry.name">{{ entry.label }}</option>
        </select>
      </div>
      <button @click="submitEnterprise" class="action-button">提交</button>
      <button @click="$emit('go-back')" class="action-button">取消</button>
    </div>

    <!-- 查看模式 -->
    <div v-else-if="mode === 'view'">
      <div class="enterprise-box">
        <div>企业名称: {{ enterprise.name }}</div>
      </div>
      <div class="enterprise-box">
        <div>区域: {{ getRegionName(enterprise.regionId) }}</div>
      </div>
      <div class="enterprise-box">
        <div>地址: {{ enterprise.address }}</div>
      </div>
      <div v-for="(value, index) in enterpriseAttrs" :key="index" class="enterprise-box">
        <div>{{ value.attrName }}: {{ value.attrValue }}</div>
      </div>
      <button @click="startEditing" class="action-button">编辑</button>
    </div>

    <!-- 编辑模式 -->
    <div v-else>
      <div class="enterprise-box">
        <label>企业名称</label>
        <input v-model="enterprise.name" placeholder="请输入企业名称" />
      </div>
      <div class="enterprise-box">
        <label>区域</label>
        <select v-model="enterprise.regionId">
          <option v-for="region in regions" :key="region.id" :value="region.id">{{ region.name }}</option>
        </select>
      </div>
      <div class="enterprise-box">
        <label>地址</label>
        <input v-model="enterprise.address" placeholder="请输入地址" />
      </div>
      <div v-for="(value, index) in enterpriseAttrs" :key="index" class="enterprise-box">
        <div>{{ value.attrName }}</div>
        <input v-model="enterpriseAttrs[index].attrValue" placeholder="请输入" class="attr-input" />
      </div>
      <button @click="openAttrDialog" class="action-button">增加属性</button>
      <div v-if="showAttrDialog" class="dialog">
        <div v-for="attr in availableAttrs" :key="attr.id" @click="addAttr(attr)" class="dialog-item">
          {{ attr.name }}
        </div>
      </div>
      <button @click="submitAttrs" class="action-button">提交</button>
      <button @click="cancelEditing" class="action-button">取消</button>
    </div>

    <hr style="margin: 20px 0; border: 1px solid #ccc;" />

    <!-- 企业负责人 -->
    <div class="current-enterprise-info">
      <span>企业负责人</span>
    </div>
    <div v-for="staff in staffList" :key="staff.id" class="enterprise-box">
      <div class="enterprise-name">{{ staff.name }}</div>
      <div class="arrow" @click="goToStaffDetail(staff.id)">...</div>
    </div>
    <button @click="addStaff" class="action-button">新增负责人</button>
  </div>
</template>

<script>
import { post } from "@/apis/restUtils";

export default {
  props: ["mode", "enterpriseId", "regions", "parentId"],
  data() {
    return {
      enterprise: {
        name: '',
        regionId: '',
        address: '',
        type:''  //partner  subsidiary
      },
      enterpriseAttrs: [],
      isEditing: false,
      showAttrDialog: false,
      availableAttrs: [],
      originalAttrs: {},
      staffList: [],
      ORIGIN_ENTERPRISE_TYPE: [
        {name: 'subsidiary', label: '子公司'},
        {name: 'holding', label: '参股公司'},
        {name: 'partner', label: '关联公司'},
      ],
      ENTERPRISE_TYPE: [],

    };
  },
  mounted() {

    if (this.mode === 'edit' || this.mode === 'view') {
      this.fetchEnterpriseInfo(this.enterpriseId);
      this.fetchStaffList(this.enterpriseId);
    } else if (this.mode === 'add') {
      this.enterprise = { name: '', regionId: '', address: '' };
      if(typeof this.parentId !== 'undefined' && this.parentId !== null && this.parentId !== ''){
        this.ENTERPRISE_TYPE = this.ORIGIN_ENTERPRISE_TYPE;
      } else {
        this.ENTERPRISE_TYPE = [{name: 'partner', label: '关联公司'}];
      }
    }
  },
  methods: {
    fetchEnterpriseInfo(id) {
      post(`/wx/enterprise/info`, { id: id }, this.fetchEnterpriseInfoConsumer);
    },
    fetchEnterpriseInfoConsumer(response) {
      if (response && response.data && response.data.entity) {
        this.enterprise = response.data.entity.enterprise;
        this.enterpriseAttrs = response.data.entity.enterpriseAttrs;
        this.availableAttrs = response.data.entity.attrs;
      }
    },
    getRegionName(regionId) {
      const region = this.regions.find(p => p.id === regionId);
      return region ? region.name : '未知';
    },
    startEditing() {
      this.originalAttrs = { ...this.enterpriseAttrs };
      this.isEditing = true;
      this.$emit('update:mode', 'edit');
    },
    submitEnterprise() {
      post('/wx/enterprise/add', {
        parentId: this.parentId,
        enterprise: this.enterprise
      }, (response) => {
        if (response && response.data && response.data.status === 'success') {
          this.$emit('go-back');
        } else {
          console.error('新增企业失败');
        }
      });
    },
    async openAttrDialog() {
      this.showAttrDialog = true;
    },
    addAttr(attr) {
      if (!this.enterpriseAttrs.some(item => item.attrId === attr.id)) {
        this.enterpriseAttrs.push({
          attrName: attr.name,
          attrType: attr.type,
          attrId: attr.id,
          attrValue: ''
        });
      }
      this.showAttrDialog = false;
    },
    submitAttrs() {
      try {
        const attrsToSave = this.enterpriseAttrs.map(attr => ({
          attrId: attr.attrId,
          content: attr.attrValue,
          entityAttrId: attr.entityAttrId,
          enterpriseId: this.enterpriseId
        }));
        post(
            `/wx/enterprises/saveAttrs`,
            {
              enterpriseId: this.enterpriseId,
              attrs: attrsToSave,
            },
            this.submitAttrsConsumer
        );
      } catch (error) {
        console.error("更新企业属性失败:", error);
      }
    },
    submitAttrsConsumer(response) {
      if (response && response.data && response.data.status === "success") {
        this.isEditing = false;
        this.$emit('update:mode', 'view');
      } else {
        console.error("更新企业属性失败");
      }
    },
    cancelEditing() {
      this.enterpriseAttrs = { ...this.originalAttrs };
      this.isEditing = false;
      this.$emit('update:mode', 'view');
    },
    fetchStaffList(enterpriseId) {
      post(`/wx/enterprises/staff`, { enterpriseId: enterpriseId }, this.fetchStaffListConsumer);
    },
    fetchStaffListConsumer(response) {
      if (response && response.data && response.data.entity) {
        this.staffList = response.data.entity;
      } else {
        console.error("获取负责人列表失败");
      }
    },
    goToStaffDetail(staffId) {
      this.$emit("select-staff", staffId);
    },
    addStaff() {
      this.$emit('add-staff', this.enterpriseId);
    }
  },
};
</script>

<style scoped>
.enterprise-info-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.back-button {
  width: 80px;

  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.action-button {
  width: 90px;
  margin: 5px 0;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.current-enterprise-info {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  text-align: center;
}

.enterprise-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: #f5f5f5;
}

.enterprise-name {
  flex: 1;
  white-space: normal;
  word-wrap: break-word;
}

.attr-input {
  flex: 2;
  padding: 5px;
  border: 1px solid #ccc;
  margin-left: 10px;
}

.arrow {
  margin-left: 10px;
  font-size: 18px;
  color: #666;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f5f5f5;
  border: 1px solid #ccc;
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
  z-index: 1000;
}

.dialog-item {
  padding: 10px;
  cursor: pointer;
}

.dialog-item:hover {
  background-color: #e0e0e0;
}
</style>