<template>
  <div>
    <component
        :is="currentComponent"
        :regions="regions"
        :region="selectedRegion"
        :isRegion="isRegion"
        :enterpriseId="selectedEnterpriseId"
        :staffId="selectedStaffId"
        :history="history"
        :mode.sync="currentMode"
        :parentId="selectedParentId"
        @select-region="handleSelectRegion"
        @select-enterprise="handleSelectEnterprise"
        @select-topEnterprise="handleSelectTopEnterprise"
        @select-staff="handleSelectStaff"
        @go-back="handleGoBack"
        @add-staff="handleAddStaff"
        @add-enterprise="handleAddEnterprise"
    />
  </div>
</template>

<script>
import wxRegionList from './wxRegionList.vue';
import wxEnterprises from './wxEnterprises.vue';
import wxEnterpriseInfo from "./wxEnterpriseInfo.vue";
import wxEnterpriseStaff from './wxEnterpriseStaff.vue'; // 新增页面D
import {get} from "@/apis/restUtils";

export default {
  components: {
    wxRegionList,
    wxEnterprises,
    wxEnterpriseInfo,
    wxEnterpriseStaff
  },
  data() {
    return {
      currentComponent: 'wxRegionList', // 默认显示页面A
      regions: [], // 存储大区数据
      selectedRegion: '', // 选中的大区
      selectedEnterpriseId: '',
      selectedStaffId: null, // 选中的负责人ID
      isRegion:true,
      history:[],
      currentMode: 'view', // 默认查看模式
      selectedParentId: null,
    };
  },
  mounted() {
    this.fetchRegions(); // 页面加载时请求大区数据
  },
  methods: {
    fetchRegions() {
      // 假设使用 get 方法获取大区数据
      get('/wx/wxGetUserRegions', this.fetchRegionsConsumer);
    },
    fetchRegionsConsumer(response) {
      if (response && response.data && response.data.entity) {
        this.regions = response.data.entity;
      } else {
        console.error('获取大区数据失败: 未知错误');
      }
    },
    handleSelectRegion(regionId) {
      this.selectedRegion = regionId;
      this.isRegion = true;
      this.currentComponent = 'wxEnterprises'; // 切换到页面B
    },
    handleSelectEnterprise(enterpriseId){
      this.selectedEnterpriseId = enterpriseId;
      this.currentMode = 'view';
      this.currentComponent = 'wxEnterpriseInfo';
    },
    handleSelectTopEnterprise(enterpriseId){
      this.selectedEnterpriseId = enterpriseId;
      this.isRegion = false;
      this.currentComponent = 'wxEnterprises';
    },
    handleSelectStaff(staffId) {
      this.selectedStaffId = staffId;
      this.currentComponent = 'wxEnterpriseStaff'; // 切换到页面D
    },
    handleGoBack() {
      if (this.currentComponent === 'wxEnterpriseStaff') {
        this.currentComponent = 'wxEnterpriseInfo'; // 从页面D返回页面C
      } else if (this.currentComponent === 'wxEnterpriseInfo') {
        this.selectedEnterpriseId = this.history.pop();
        if (typeof this.selectedEnterpriseId === 'undefined' || this.selectedEnterpriseId === null || this.selectedEnterpriseId === '') {
          this.currentComponent = 'wxRegionList';
        } else {
          this.currentComponent = 'wxEnterprises'; // 从页面C返回页面B
          this.currentMode = 'view';
        }
      } else if (this.currentComponent === 'wxEnterprises') {
        this.currentComponent = 'wxRegionList'; // 从页面B返回页面A
      }
    },
    handleAddStaff(enterpriseId) {
      this.currentComponent = 'wxEnterpriseStaff';
      this.selectedStaffId = null; // 表示新增模式
      this.selectedEnterpriseId = enterpriseId;
    },
    handleAddEnterprise(parentId) {
      this.selectedParentId = parentId;
      this.currentMode = 'add';
      this.currentComponent = 'wxEnterpriseInfo';
    },
  }
};
</script>