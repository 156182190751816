<template>
  <div class="main-container">
    <div class="enterprise-container">
      <div class="top-entity-title">
        <h3>一级企业</h3>
      </div>
      <div
          v-for="enterprise in topEnterprises"
          :key="enterprise.id"
          class="enterprise-box"
          :style="{ backgroundColor: '#e6f7ff' }"
      >
        <div class="enterprise-name" @click="fetchSubEnterprises(enterprise.id)">
          {{ enterprise.name }}
        </div>
        <div class="arrow" @click="goToEnterpriseDetail(enterprise.id)">...</div>
      </div>
    </div>

    <div class="region-title">
      <h3>业务大区</h3>
    </div>
    <div class="province-container">
      <div
          v-for="region in regions"
          :key="region.id"
          class="province-box"
          @click="selectRegion(region.id)"
      >
        {{ region.name }}
      </div>
    </div>

  </div>
</template>

<script>
import {post} from "@/apis/restUtils";
export default {
  props: ['regions'], // 接收从 MainPage 传递的省份数据
  data() {
    return {
      topEnterprises: [] // 一级企业数据
    };
  },
  mounted() {
    this.fetchTopEnterprises(); // 获取一级企业
  },
  methods: {
    selectRegion(regionId) {
      this.$emit('select-region', regionId); // 触发事件通知 MainPage
    },
    fetchTopEnterprises() {
      post('/wx/enterprise/top', {}, (response) => {
        if (response && response.data && response.data.entity) {
          this.topEnterprises = response.data.entity;
        }
      });
    },
    goToEnterpriseDetail(enterpriseId) {
      this.$emit('select-enterprise', enterpriseId); // 切换到企业详情页面
    },

    fetchSubEnterprises(enterpriseId) {
      this.$emit('select-topEnterprise', enterpriseId); // 触发事件通知 MainPage
    },
  }
};
</script>

<style scoped>
.main-container {
  height: 100vh; /* 占满屏幕高度 */
  overflow-y: auto; /* 允许垂直滚动 */
  -webkit-overflow-scrolling: touch; /* 优化移动端滚动体验 */
}

.province-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  padding: 20px;
}

.province-box {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.province-box:hover {
  background-color: #f0f0f0;
}

.enterprise-container {
  margin-top: 20px;
  padding: 20px;
}

.region-title {
  margin-top: 10px;
  margin-left: 20px;
}

.top-entity-title {
  margin-bottom: 10px;
}

.enterprise-box {
  display: flex;
  justify-content: space-between; /* 名称和箭头两端对齐 */
  align-items: center; /* 垂直居中 */
  border: 1px solid #ccc; /* 边框 */
  padding: 10px; /* 内边距 */
  width: 100%; /* 占据整行宽度 */
  cursor: pointer; /* 鼠标悬停时显示手型 */
  margin-bottom: 10px;
}

.enterprise-name {
  flex: 1;
  white-space: normal; /* 支持换行 */
  word-wrap: break-word; /* 长词换行 */
}

.arrow {
  font-size: 18px;
  color: #666;
}

</style>