<template>
  <div class="staff-detail-container">
    <!-- 返回按钮 -->
    <button @click="$emit('go-back')" class="back-button">返回</button>

    <!-- 当前负责人信息 -->
    <div class="current-staff-info">
      <span>负责人详情</span>
    </div>

    <div v-if="!isEditing">
      <div class="staff-box">
        <div>{{ staff.name }}</div>
      </div>
      <div v-for="(value, index) in staffAttrs" :key="index" class="staff-box">
        <div>{{ value.attrName }}: {{ value.attrValue }}</div>
      </div>
      <button @click="startEditing" class="action-button">编辑</button>
      <button @click="deleteStaff" class="action-button">删除</button>
    </div>

    <div v-else>
      <div class="staff-box">
        <input v-model="staff.name" placeholder="请输入姓名" />
      </div>
      <div v-for="(value, index) in staffAttrs" :key="index" class="staff-box">
        <div>{{ value.attrName }}</div>
        <input v-model="staffAttrs[index].attrValue" placeholder="请输入" class="attr-input" />
      </div>
      <button v-if="staffId" @click="openAddAttrDialog" class="action-button">增加属性</button>
      <div v-if="showAddAttrDialog" class="dialog">
        <div v-for="attr in availableAttrs" :key="attr.id" @click="addNewAttr(attr)" class="dialog-item">
          {{ attr.name }}
        </div>
      </div>
      <button @click="submitChanges" class="action-button">提交</button>
      <button @click="cancelEditing" class="action-button">取消</button>
    </div>
  </div>
</template>

<script>
import { post } from '@/apis/restUtils';

export default {
  props: {
    enterpriseId: String,
    staffId: String
  },
  data() {
    return {
      staff: {},
      staffAttrs: [],
      isEditing: false,
      showAddAttrDialog: false,
      availableAttrs: [],
      originalAttrs: {},
    };
  },
  mounted() {
    // this.fetchStaffInfo(this.staffId);
    if (this.staffId) {
      // 编辑模式：加载现有负责人信息
      this.fetchStaffInfo(this.staffId);
    } else {
      // 新增模式：初始化空白数据
      this.isEditing = true;
      this.staff = { name: ''};
      this.staffAttrs = [];
    }
  },
  methods: {
    fetchStaffInfo(staffId) {
      post(`/wx/enterpriseStaff/info`, { staffId: staffId }, this.fetchStaffInfoConsumer);
    },
    fetchStaffInfoConsumer(response) {
      if (response && response.data && response.data.entity) {
        this.staff = response.data.entity.staff;
        this.staffAttrs = response.data.entity.staffAttrs;
        this.availableAttrs = response.data.entity.attrs;
        this.originalAttrs = { ...response.data.entity.staffAttrs };
      } else {
        console.error('获取负责人详情失败');
      }
    },
    startEditing() {
      this.isEditing = true;
    },
    async openAddAttrDialog() {
      if (!this.availableAttrs.length) {
        try {
          await post('/wx/getAvailableStaffAttrs', null, response =>{
            this.availableAttrs = response.data.entity;
          });
        } catch (error) {
          console.error('获取可用属性失败:', error);
        }
      }
      this.showAddAttrDialog = true;
    },
    addNewAttr(attr) {
      if (!this.hasAttr(attr)) {
        let item = {
          attrName: attr.name,
          attrType: attr.type,
          attrId: attr.id,
          attrValue: ''
        };
        this.staffAttrs.push(item);
      }
      this.showAddAttrDialog = false;
    },
    hasAttr(attr) {
      return this.staffAttrs.some((item) => item.attrId === attr.id);
    },
    // submitChanges() {
    //   post(`/wx/enterpriseStaff/saveAttrs`, { staffId: this.staffId, attrs: this.staffAttrs }, this.submitConsumer);
    // },
    // submitConsumer(response) {
    //   if (response && response.data && response.data.status === 'success') {
    //     this.isEditing = false;
    //     this.originalAttrs = { ...this.staffAttrs };
    //   } else {
    //     console.error('更新负责人信息失败');
    //   }
    // },
    submitChanges() {
      if (this.staffId) {
        // 编辑模式：更新负责人
        post('/wx/enterpriseStaff/update', {
          enterpriseId: this.enterpriseId,
          staffId: this.staffId,
          staff: this.staff,
          attrs: this.staffAttrs
        }, this.submitConsumer);
      } else {
        console.log('submitChanges ...', this.enterpriseId, this.staff, this.staffAttrs);
        // 新增模式：添加负责人
        post('/wx/enterpriseStaff/add', {
          enterpriseId: this.enterpriseId,
          staff: this.staff,
          attrs: this.staffAttrs
        }, this.submitConsumer);
      }
    },
    submitConsumer(response) {
      if (response && response.data && response.data.status === 'success') {
        this.$emit('go-back'); // 返回企业信息页面
      } else {
        console.error('操作失败');
      }
    },
    cancelEditing() {
      this.staffAttrs = { ...this.originalAttrs };
      this.isEditing = false;
    },
    deleteStaff() {
      if (confirm('确定要删除该负责人吗？')) {
        post(`/wx/enterprises/deleteStaff`, { id: this.staffId }, (response) => {
          if (response && response.data && response.data.status === 'success') {
            this.$emit('go-back');
          } else {
            console.error('删除负责人失败');
          }
        });
      }
    },
  }
};
</script>

<style scoped>
.staff-detail-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.back-button {
  width: 80px;
  margin-left: auto;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.action-button {
  width: 80px;
  margin: 5px 0;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.current-staff-info {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  text-align: center;
}

.staff-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #f5f5f5;
}

.attr-input {
  flex: 2;
  padding: 5px;
  border: 1px solid #ccc;
  margin-left: 10px;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f5f5f5;
  border: 1px solid #ccc;
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
  z-index: 1000;
}

.dialog-item {
  padding: 10px;
  cursor: pointer;
}

.dialog-item:hover {
  background-color: #e0e0e0;
}
</style>